<template>
  <section class="applicant-rejected">
    <applicant-rejected-reason
      data-test="rejected-reason"
      @input="handleReasonInput"
    />
    <applicant-rejected-feedback
      :applicant="applicant"
      data-test="rejected-feedback"
      :show-template-feedback="showFeedbackTemplate"
      @input="handleFeedbackInput"
    />
    <ev-button
      v-if="hasReason && !hasFeedback"
      data-test="button-send-feedback"
      class="applicant-rejected__actions"
      :disabled="!rejectionMessage"
      @click="handleReject"
    >
      {{ $t(`${langPath}.sendFeedback`) }}
    </ev-button>
    <section
      v-else-if="!hasReason"
      class="applicant-rejected__actions applicant-rejected__actions--multi"
    >
      <ev-button
        class="applicant-rejected__button"
        data-test="button-cancel"
        variant="secondary"
        color="blue"
        @click="handleCancel"
      >
        {{ $t(`${langPath}.cancel`) }}
      </ev-button>
      <ev-button
        class="applicant-rejected__button"
        data-test="button-reject"
        :disabled="!rejectionReason"
        @click="handleReject"
      >
        {{ $t(`${langPath}.reject`) }}
      </ev-button>
    </section>
  </section>
</template>

<script>
import ApplicantRejectedReason from './components/ApplicantRejectedReason'
import ApplicantRejectedFeedback from './components/ApplicantRejectedFeedback'
import { TheNotificationCardList } from '@revelotech/everest'

import { createNamespacedHelpers } from 'vuex'
import { APPLICANTS, STAGES } from '@/store/namespaces'
import EvButton from '@revelotech/everestV2/EvButton'

const stagesHelper = createNamespacedHelpers(STAGES)
const applicantsHelper = createNamespacedHelpers(APPLICANTS)

export default {
  name: 'ApplicantRejected',
  components: {
    ApplicantRejectedReason,
    ApplicantRejectedFeedback,
    EvButton
  },
  data () {
    return {
      langPath: __langpath,
      rejectionMessage: '',
      rejectionReason: ''
    }
  },
  computed: {
    ...applicantsHelper.mapGetters([
      'applicant',
      'stageOfApplicant'
    ]),
    hasReason () { return !!this.applicant?.rejectionReason },
    hasFeedback () { return !!this.applicant?.rejectionMessage },
    showFeedbackTemplate () { return !!this.rejectionReason }
  },
  created () {
    if (this.$route.query.referral === 'external') {
      const { referral, ...queryWithoutReferral } = this.$route.query

      this.$smartlook('track', 'clicked_rejected_invites')
      this.$router.replace({ query: queryWithoutReferral })
    }
  },
  methods: {
    ...stagesHelper.mapActions(['rejectCandidate']),
    handleReasonInput (value) { this.rejectionReason = value },
    handleFeedbackInput (value) { this.rejectionMessage = value },
    handleCancel () { this.$router.back() },
    async handleReject () {
      await this.rejectCandidate({
        applicantId: this.$route.params.applicantId,
        currentStageId: this.stageOfApplicant?.id,
        rejectionMessage: this.rejectionMessage || null,
        rejectionReason: this.rejectionReason || null
      }).then(() => {
        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.rejectCandidateSuccessMessage`),
          modifier: 'success'
        })
        this.closeForm()
      }).catch(() => {
        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.rejectCandidateErrorMessage`),
          modifier: 'error'
        })
      })
    },
    closeForm () {
      this.$router.push({
        name: 'WorkflowBoard',
        params: { positionId: this.$route.params.positionId }
      })
    }
  }
}
</script>

<style lang="scss">
.applicant-rejected {
  border-top: 1px solid $gray20;
  margin-top: $base*6;
  padding-top: $base*6;

  &__button {
    margin-left: $base*4;
  }

  &__actions {
    margin-top: $base*4;

    &--multi {
      display: flex;
      justify-content: flex-end;
      padding-top: $base*4;
    }
  }
}
</style>
